:root {
  --city-red: #e81f29 !important;
  --city-white: #efefef !important;
  --city-black: #000000de !important;
  --city-light-red: #e81f296d !important;
}

body {
  margin: 0;
  padding-top: 90px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none !important;
  color: var(--city-black) !important;
}

.shadow {
  box-shadow: 0 0.125rem 0.5rem -0.25rem rgba(232, 31, 41, 0.634), 0 0.25rem 1rem rgba(232, 31, 41, 0.634) !important;
}

.shadow-h:hover {
  /* box-shadow: 0 0.125rem 0.5rem -0.25rem rgba(232, 31, 41, 0.634), 0 0.25rem 1rem rgba(232, 31, 41, 0.634) !important; */
  box-shadow: rgba(232, 31, 41, 0.634) 0px 30px 60px -12px, rgba(83, 3, 7, 0.634) 0px 18px 36px -18px !important;
}

.ov-card {
  border: 1px solid #e81f29 !important;
  margin-bottom: 10px;
  padding: 0 !important;
}

.red-txt {
  color: var(--city-red) !important;
}

.bdr {
  border: 1px solid #333;
}

.vertical {
  display: inline;
  border: none;
  margin: 0 10px;
  border-left: 3px solid black;
  min-height: 20px;
}

/* .glossy-txt {
  background: linear-gradient(to bottom, transparent, #e6e6e6);
  color: #333333;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); 
} */

.navbar {
  font-size: 1.1em;
}

.nav-item {
  border-radius: 8px 8px;
  padding: 3px 3px;
}

.nav-item:hover {
  border-radius: 8px 8px;
  background-color: var(--city-light-red);
  color: var(--city-white) !important;
  padding: 3px 3px;
}

.nav-link {
  color: var(--city-red) !important;
  font-weight: 500 !important;
}

/* .nav-link:hover {
  color: var(--city-white) !important;
} */

/* p {
  color: rgba(232, 31, 41, 0.634);
} */

.btn-w {
  background-color: var(--city-white) !important;
}

.btn-r {
  background-color: var(--city-red) !important;
  color: var(--city-white) !important;
}

.car-img-bk {
  height: 100px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.car-description {
  font-size: 15px !important;
}

.slider-cont {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  height: 50vh;
  padding-top: 60px;
  padding-left: 30px;
  background-image: url('./images/cover_new3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-cont > .row {
  border: 1px solid #333;
}

.main-content {
  display: flex;
  flex-direction: column;
  color: var(--city-white);
}

.main-content > h2 {
  color: var(--city-white);
  font-variant-caps: all-petite-caps;
}

@media only screen and (min-width: 768px) {
  .slider-cont {
    height: 80vh;
    display: flex;
    padding-top: 140px;
    justify-content: flex-start;
    /* align-items: center; */
  }

  .main-content {
    display: flex;
    flex-direction: column;
    color: var(--city-white);
    background-color: var(--city-light-red);
    padding: 5px 15px;
    border-radius: 15px 15px;
  }

  .main-content > h2,
  .main-content > h3 {
    color: var(--city-white);
    font-size: 3.1em;
    font-weight: 800;
    font-variant-caps: all-petite-caps;
  }

  .slider-cont > .row {
    flex-direction: row;
  }
}

.carousel-item > .d-block {
  height: 40vh;
}

.carousel-caption {
  top: 50px;
  width: 600px;
  /* margin: 0 auto; */
}

.icon-cont {
  color: var(--city-red);
}

.overview {
  /* border: 1px solid #333; */
  background-color: var(--city-red);
  color: var(--city-white) !important;
}

/* .overview-cont {
  border: 1px solid #333;
} */

.footer {
  color: var(--city-white);
  background-color: var(--city-red);
}

.footer-sub {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 25px;
}

.foot-logo-cont {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  border-radius: 8px 8px;
  margin: 0 auto;
  margin-top: 15px;
}

.footer-logo {
  margin: 0 auto;
  width: inherit;
  height: inherit;
}

.footer-sub {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 25px;
}

.footer-logo {
  width: inherit;
  height: inherit;
}

.footer {
  background-color: var(--city-red);
  padding: 40px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  /* flex: 1 1 300px; */
  margin-bottom: 20px;
  /* border: 1px solid #333; */
  padding: 15px;
}

.footer h3 {
  color: var(--city-white);

  font-size: 1.3em;
  margin-bottom: 10px;
}

.footer p {
  color: var(--city-white);

  font-size: 14px;
  margin-bottom: 5px;
}

.social-icons {
  display: flex;
}

.social-icons a {
  color: var(--city-white) !important;
  font-size: 20px;
  margin-right: 10px;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  color: var(--city-white);
  font-size: 12px;
}

.about-section {
  /* background-color: var(--city-red); */
  padding: 60px 0;
}

.container-about {
  max-width: 900px;
  margin: 0 auto;
  padding: 15px 15px;
}

.section-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  /* color: var(--city-white) !important; */
}

.section-description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  /* color: var(--city-white) !important; */
}

.team-section {
  text-align: center;
  padding: 40px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.team-member {
  width: 300px;
  margin: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.team-member:hover {
  transform: translateY(-5px);
}

.member-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
}

.team-member h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.team-member p {
  font-size: 0.9rem;
  color: #555;
}

.tour-packages-page {
  padding: 60px 0;
}

.page-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.tour-packages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.tour-package {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
}

.package-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.package-description {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 15px;
}

.package-price {
  font-size: 18px;
  font-weight: 600;
}

.tour-package-page {
  padding: 60px 0;
}

.page-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.tour-package-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.package-image {
  flex: 0 0 300px;
  margin-right: 20px;
}

.package-image img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.package-info {
  flex: 1;
}

.package-description {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.package-price {
  font-size: 32px;
  font-weight: 700;
  color: #ff6b6b;
  margin-bottom: 10px;
}

.package-duration {
  font-size: 20px;
  color: #777;
}

.section-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.itinerary-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.itinerary-list li {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.4;
}

.itinerary-list li:before {
  content: '\2023';
  color: #ff6b6b;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.price-and-btn {
  align-items: center;
}

/* .itinerary-cont {
} */

/* Contact Page */

.contact-page {
  background-color: #ffffff !important;
}

.contact-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.page-heading {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.page-description {
  color: #666;
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
}

.contact-form {
  border-radius: 8px 8px;
  margin-bottom: 30px;
  /* border: 1px solid #333; */
  max-width: 800px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

button[type='submit'] {
  background-color: #333;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type='submit']:hover {
  background-color: #555;
}

.contact-details {
  margin-top: 40px;
}

/* .contact-form-cont {
  margin-top: 40px;
} */

.branch {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.branch h4 {
  margin-bottom: 10px;
  font-size: 20px;
}

.branch p {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

@media screen and (max-width: 600px) {
  .contact-cont {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }

  .contact-page {
    padding: 10px;
    background-color: var(--city-red);
  }

  .page-heading {
    font-size: 24px;
  }

  .page-description {
    font-size: 14px;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    font-size: 12px;
  }

  button[type='submit'] {
    font-size: 14px;
    padding: 10px 20px;
  }

  .branch h4 {
    font-size: 16px;
  }

  .branch p {
    font-size: 12px;
  }
}

.car-rental-page {
  padding: 60px 0;
}

.page-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.page-description {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}

.car-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
}

.car-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease;
}

.car-card:hover {
  transform: translateY(-5px);
}

.car-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.car-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.car-description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.rent-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rent-btn:hover {
  background-color: #ff6b6b;
}

.car-detail-page {
  padding: 60px 0;
}

.car-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.car-image {
  width: 300px;
  height: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.car-details {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.car-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

.car-description {
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;
}

.car-features {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.car-features li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
}

.car-price {
  font-size: 24px;
  margin-bottom: 20px;
}

.price-label {
  font-weight: 700;
  color: #333;
}

.price-value {
  font-weight: 700;
  color: #ff6b6b;
}

.rent-btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #ff6b6b;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rent-btn:hover {
  background-color: #e25a5a;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f2f2f2;
}

.login-page-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: #f2f2f2;
  flex-wrap: wrap;
}

.login-guide {
  max-width: 600px;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.login-container {
  max-width: 400px;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.login-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #666;
}

input[type='phone'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.login-btn {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .login-btn:hover {
  background-color: #e25a5a;
} */

.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

.signup-container {
  max-width: 500px;
  padding: 35px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signup-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.signup-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #666;
}

input[type='text'],
input[type='phone'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.signup-btn {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .signup-btn:hover {
  background-color: #e25a5a;
} */

.user-profile-page {
  padding: 60px 0;
}

.user-profile-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-profile-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.user-profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-details {
  flex-grow: 1;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.detail-label {
  font-weight: 600;
  margin-right: 10px;
  color: #666;
}

.detail-value {
  color: #333;
}

.edit-profile-btn {
  display: block;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: none;
}

.user-dashboard-page {
  padding: 60px 10px;
}

.user-dashboard-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.user-dashboard-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
}

.dashboard-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  border-radius: 8px 8px;
}

.dashboard-menu-item i {
  font-size: 24px;
  margin-bottom: 10px;
}

.dashboard-menu-item span {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.car-rental-history-page {
  padding: 60px 0;
}

.car-rental-history-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.car-rental-history-item {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 8px;
}

.car-rental-history-item h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.car-rental-history-item p {
  font-size: 16px;
  margin-bottom: 5px;
}

.guided-tour-history-page {
  padding: 60px 0;
}

.guided-tour-history-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.guided-tour-history-item {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 8px;
}

.guided-tour-history-item h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.guided-tour-history-item p {
  font-size: 16px;
  margin-bottom: 5px;
}

.testimonials-page {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.page-heading {
  color: #333;
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.testimonial {
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  text-align: center;
}

.testimonial-comment {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.testimonial-info {
  font-size: 14px;
  color: #666;
}

.testimonial-name {
  margin-bottom: 5px;
  font-weight: bold;
}

.testimonial-occupation {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .testimonials-page {
    padding: 10px;
  }

  .page-heading {
    font-size: 24px;
  }

  .testimonial {
    margin: 10px;
  }

  .testimonial-comment {
    font-size: 14px;
  }

  .testimonial-info {
    font-size: 12px;
  }
}

.clients-section {
  background-color: var(--city-white);
  padding: 40px 0;
  text-align: center;
}

.section-heading {
  color: #333;
  font-size: 28px;
  margin-bottom: 30px;
}

.clients-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.client {
  margin: 20px;
}

.client-logo {
  width: 120px;
  height: auto;
}

.client-name {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

@media screen and (max-width: 600px) {
  .section-heading {
    font-size: 24px;
  }

  .client-logo {
    width: 80px;
  }

  .client-name {
    font-size: 14px;
  }
}

/* .products-and-services {
} */

.products-services-section {
  padding: 40px 0;
  text-align: center;
}

.section-heading {
  color: #333;
  font-size: 28px;
  margin-bottom: 30px;
}

.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  align-items: flex-start;
}

.product {
  max-width: 280px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.product-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.product-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.product-description {
  font-size: 14px;
  color: #666;
}

@media screen and (max-width: 600px) {
  .section-heading {
    font-size: 24px;
  }

  .product {
    max-width: 100%;
  }

  .product-image {
    margin-bottom: 15px;
  }

  .product-title {
    font-size: 18px;
  }

  .product-description {
    font-size: 12px;
  }
}

.car-slider {
  max-width: 800px;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  height: auto;
}

.faq-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.carousel-container {
  border: 3px solid #333;
  height: 60vh;
}

.swiper {
  width: 100%;
  height: 40vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 40vh;
  /* object-fit: cover; */
}

@media only screen and (min-width: 768px) {
  .swiper {
    width: 100%;
    height: 60vh;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 60vh;
    /* object-fit: cover; */
  }
}

/* CSS for SafariCamperPage */
/* Global styles */

.safari-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

h1,
h2 {
  color: #333;
  margin-bottom: 10px;
}

p {
  color: #666;
  line-height: 1.5;
}

ul {
  list-style-type: none;
  padding-left: 20px;
}

/* Image gallery styles */
.mySwiper {
  width: 100%;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
}

.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

/* Partner campsites styles */
.partner-campsites {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.partner-campsites li {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  /* text-align: center; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Camper price styles */
.camper-price {
  margin-top: 20px;
}

.camper-price li {
  margin-bottom: 10px;
  color: #333;
  display: flex;
  align-items: center;
}

.camper-price li::before {
  content: '-';
  margin-right: 5px;
}

/* Media queries for responsiveness */
@media (max-width: 600px) {
  .container {
    padding: 10px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }
}

.newsletter-section {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.newsletter-section h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.newsletter-section form {
  display: flex;
  flex-direction: column;
}

.newsletter-section label {
  font-weight: bold;
  margin-bottom: 5px;
}

.newsletter-section input[type='text'],
.newsletter-section input[type='email'] {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.newsletter-section button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.newsletter-section button:hover {
  background-color: #45a049;
}

/* NewsPage.css */

.news-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.news-article {
  margin-bottom: 20px;
}

.news-article h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.news-article a {
  color: #007bff;
  text-decoration: none;
}

.news-article a:hover {
  text-decoration: underline;
}

/* ArticlePage.css */

.article-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.article-title {
  font-size: 32px;
  margin-bottom: 20px;
}

.article-content {
  font-size: 18px;
  line-height: 1.5;
}

.news-card {
  height: inherit;
}

.success-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.success-container h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
}

.success-container p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
}

.success-icon {
  font-size: 48px;
  margin-bottom: 20px;
  color: #55c57a;
}

/* Container styles */

.contact-section {
  background-color: var(--city-red);
}

.contact-sect-container {
  padding-top: 80px;
  padding-bottom: 80px;
  color: var(--city-white) !important;
}

.contact-sect-container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

/* Section heading styles */
.section-heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}

/* Divider styles */
.divider {
  width: 50px;
  border-top: 3px solid var(--city-light-red);
  margin: 0 auto;
  margin-bottom: 30px;
}

/* Section subheading styles */
.section-subheading {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 50px;
  color: #888;
}

/* Contact CTA styles */
.contact-cta {
  margin-bottom: 50px;
}

/* Contact info styles */
.contact-info {
  font-size: 20px;
  color: #555;
  margin-bottom: 20px;
}

/* Phone and envelope icon styles */
.contact-info i {
  margin-right: 10px;
  color: #f15b2a;
}

/* Contact image styles */
.contact-image img {
  height: 350px;
  border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .col-lg-6,
  .text-lg-left {
    text-align: center !important;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wiggle-btn {
  animation: wiggle 2s infinite;
  animation-delay: 1s;
  animation-fill-mode: both;
}

.prop-entry {
  position: relative;
  overflow: hidden;
}
.prop-entry img,
.prop-entry figure {
  margin-bottom: 0;
}
.prop-entry .prop-text {
  position: absolute;
  bottom: -50px;
  width: 100%;
  z-index: 2;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.prop-entry .prop-text .inner {
  padding-left: 20px;
  padding-right: 20px;
}
.prop-entry .prop-text:before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(18%, transparent),
    color-stop(99%, rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
}
.prop-entry .prop-text .prop-more-info {
  /* font-size: 14px; */
  /* background: var(--city-light-red); */
  position: relative;
  z-index: 4;
  padding-top: 15px;
  padding-bottom: 15px;
}
.prop-entry .prop-text .prop-more-info .col {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  color: #fff;
}
.prop-entry .prop-text .prop-more-info span {
  display: block;
}
.prop-entry .prop-text .price {
  background: var(--city-light-red);
  color: #fff;
  padding: 4px 10px;
  display: inline-block;
  margin-bottom: 20px;
}
.prop-entry .prop-text .title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0;
}
.prop-entry .prop-text .location {
  font-size: 15px;
  color: #e6e6e6;
}
.prop-entry:hover .prop-text {
  bottom: 0;
}

.feature .icon {
  font-size: 3rem;
  line-height: 0;
  position: relative;
  top: -3px;
  color: var(--city-light-red);
}

.feature .text h2 {
  font-size: 18px;
  color: #000;
}

.feature .text p {
  color: #888;
}

/* Booking Modal */

.modal-dialog {
  max-width: 800px !important;
}
/* 
.modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
*/

.form-group {
  margin-bottom: 35px !important;
}

.two-column-form {
  display: flex;
  flex-wrap: wrap;
}

.form-row {
  display: flex;
  flex-basis: 100%;
  /* border: 1px solid #333; */
}

.form-column {
  flex-basis: 100%;
  padding-right: 10px;
}

.form-column > {
  margin-bottom: 20px;
  padding-top: 30px !important;
}

.driver-radio {
  display: flex;
  flex-direction: row;
}

.driver-radio > .form-check {
  width: 100px;
}

.num-children {
  display: flex;
  flex-direction: row;
  gap: 2;
}

.num-children > .form-group {
  /* width: 30px; */
  border: 1px solid #333;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
    padding-right: 0;
  }
}

/* .courtesy-container {
} */

.header {
  text-align: center;
  margin-bottom: 30px;
}

.illustration {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.illustration img {
  width: 80%;
  max-width: 400px;
  height: auto;
}

.benefits {
  margin-top: 30px;
}

.benefit-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.benefit-item-icon {
  margin-right: 10px;
  color: #4f9deb;
}

.discount {
  margin-top: 30px;
}

.discount-notice {
  font-size: 18px;
  margin-bottom: 10px;
}

.discount-requirements {
  font-size: 16px;
}

/* Quote styles */

#invoiceholder {
  border: 1px solid #333;
  padding-top: 50px;
}

#invoice {
  position: relative;
  /*   top: 290px; */
  margin: 0 auto;
  background: #fff;
}

[id*='invoice-'] {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #eee;
  padding: 30px;
}

#invoice-top {
  min-height: 160px;
}
#invoice-mid {
  min-height: 180px;
}
#invoice-bot {
  min-height: 250px;
}

.logo {
  float: left;
  height: 50px;
  width: 150px;
  background-size: 60px 60px;
}

/* .title p{text-align: right;} */
#project {
  margin-left: 0;
  /* border: 1px solid red; */
}
table {
  border-collapse: collapse;
}
td {
  padding: 5px 0 5px 15px;
  border: 1px solid #eee;
}
.tabletitle {
  padding: 5px;
  background: #eee;
}
.service {
  border: 1px solid #eee;
}
.item {
  width: 35%;
}
.itemtext {
  font-size: 1em;
}
/* 
#legalcopy {
  margin-top: 30px;
} */

.paypal-btn {
  float: right;
  margin-top: 30px;
  text-align: right;
}

.effect2 {
  position: relative;
}
.effect2:before,
.effect2:after {
  z-index: -1;
  position: absolute;
  content: '';
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.effect2:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.legal {
  font-size: 1.1em;
}

/*
  Terms of Service
*/

.trading-conditions-container {
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 8px 8px;
}

.trading-conditions-title {
  color: var(--city-red);
  margin-bottom: 30px;
}

.condition {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
}

.condition h2 {
  color: var(--city-red);
  margin-bottom: 10px;
}

.condition p {
  margin-bottom: 10px;
  padding: 0 15px;
}


/*
  User Dashabord
*/

.sidebar-cont {
  position: relative;
 
  min-height: 80vh;
  height: 100vh;
  overflow-y: scroll;

}

.sidebar-cont > .card {
  min-height: 80vh;
  height: 100vh;
}

.sidebar-content {
  /* padding: 20px; */
  overflow-y: scroll;
  min-height: 80vh;
  height: 80vh;
  /* border: 1px solid #333; */
}

.user-detail-card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}


.user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.user-img > .img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-email-sm {
  font-size: 11px;
}


.dash-link > div {
  color: var(--city-red) !important;
  background-color: #eee !important;
  border-radius: 8px 8px;

}

.dash-link > div:hover {
  background-color: var(--city-light-red) !important;
  padding: 0;
  margin: 0;
  border-radius: 8px 8px;
  cursor: pointer;
}

.dash-active > div {
  background-color: var(--city-light-red) !important;
  padding: 0;
  margin: 0;
  border-radius: 8px 8px;
}

.top-back-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.more-btn {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.card-img-overlay > .card-title {
  font-weight: 900;
  /* width: fit-content; */
  padding: 0 15px;
  text-align: center;
  font-size: 1.4rem;
  border-radius: 10px 10px;
  /* background-color: rgba(0, 0, 0, 0.4); */
}

/*
 Courtesy List Styles
*/

ol.lead > li {
  padding: 5px 0;
  color: var(--city-red);
  text-align: left;
}

ul.requirements-list > li {
  list-style: decimal;
  padding: 5px 0;
  text-align: left;
}

.error-message {
  color: var(--city-red);
  font-size: 12px;
  margin-top: 4px;
}

